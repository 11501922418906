import RoboNumberConstants from "./robo-number-constants"

export default class Triplet {

	inputString: string = undefined
	wordRepresentation: string = undefined
	words: string[] = []

	WORD_DELIMITER: string = " "

	constructor(inString: string) {
		this.inputString = inString
		this.wordRepresentation = this._getWord()
		this.words = this.wordRepresentation.split(this.WORD_DELIMITER)
	}

	// Gets the word representation for a triplet of digits
	_getWord = (): string => {

		let digits: string[] = Array.from(this.inputString)
		// 456 ==> Four-hundred fifty-six
		let tripletWord: string = ""

		// Traverse the triplet from most-significant to least-significant place
		for (let i: number = 0; i < digits.length; i++) {
			// Ignore digits that are 0
			if (digits[i] === "0") continue
			let suffix: string = (i.toString() ===
				RoboNumberConstants.DIGIT_PLACE_INDEXES.Hundred)
				? " Hundred" : ""

			// Handle the "relative" tens place
			if (i === RoboNumberConstants.RELATIVE_TENS_PLACE_INDEX) {

				// Closest tens place value (10, 20, 30, ...)
				let closestTensPlace: string = `${digits[i]}0`
				// Actual last two digits
				let lastTwoDigits: string = `${digits[i]}${digits[i + 1]}`

				let containsLastTwoDigits: boolean =
					(RoboNumberConstants.NUMBER_WORDS[parseInt(lastTwoDigits)]
						!== undefined)

				let lastTwoDigitsWord: string =
					RoboNumberConstants.NUMBER_WORDS[lastTwoDigits.toString()]

				let closestTensPlaceWord: string =
					RoboNumberConstants.NUMBER_WORDS[closestTensPlace.toString()]

				// If the last two digits end in 0, then stop parsing the triplet
				if (containsLastTwoDigits) {
					// Last two digits will be the last two digits word representation
					tripletWord = `${tripletWord}${lastTwoDigitsWord}${suffix}`
					break
					// Else continue parsing, but don't append the current digit's word
					// since we did it above
				} else {
					tripletWord = `${tripletWord}${closestTensPlaceWord}${suffix}`
					continue
				}
			}

			tripletWord = `${tripletWord}
			${RoboNumberConstants.NUMBER_WORDS[digits[i].toString()]}${suffix} `

		}
		return tripletWord.trim()

	}

	getWord = (): string => {
		return this.wordRepresentation
	}

	addSuffix = (suffix: string) => {
		if (/\S/.test(suffix) && suffix !== undefined) {
			// string is not empty and not just whitespace
			this.words.push(suffix.trim())
		}
		this.wordRepresentation += suffix
	}
}
