
import Triplet from "./triplet"
import RoboNumberConstants from "./robo-number-constants"

export default class RoboNumber {

  triplets: Triplet[] = []
  inputNumber: number = undefined
  wordRepresentation: string = undefined

  constructor(num: string) {
    this.inputNumber = parseInt(num)
    this.wordRepresentation = this.convertNumberToWord(this.inputNumber)
  }

  toString = (): string => {
    return this.wordRepresentation
  }

  // Converts a given number to it's word representation
  convertNumberToWord = (num: number): string => {
    let numString: string = num.toString()

    // Add most-significant place zeroes until there are enough digits to
    // create triplets
    while (numString.length % 3 !== 0) {
      numString = "0" + numString
    }

    // Get digit triplets
    this.triplets = this.getDigitTriplets(numString)

    let word: string = ""
    // Traverse the triplets from least-significant to most-significant place
    for (let i: number = this.triplets.length - 1; i >= 0; i--) {

      // Get the large number suffix
      let suffix: string = this.getLargeNumberSuffix(i, this.triplets.length)

      this.triplets[i].addSuffix(suffix)
      let tripletWord: string = this.triplets[i].getWord()
      let comma: string = (tripletWord !== "") ? "," : ""

      word = `${tripletWord}${comma} ${word}`
    }

    // Trim the extra comma off
    word = word.trim()
    word = word.substr(0, word.length - 1)

    return word

  }

  // Gets the large number suffix for a given place relative to the number of
  // triplets
  // associated with the number.
  getLargeNumberSuffix = (placeIdx: number, numOfTriplets: number): string => {
    /* _________________________
      | 123, | 456, | 789 | ... |
       -------------------------
         0      1      2     3
    
      placeIndex - 0, 1, 2, or 3
      numberOfTriplets - number of boxes

      Get suffix for DIGIT_PLACE_INDEXES[suffix] = x, where x = number of boxes
      away from end of right
    */
    let largeNumberSuffix: string = ""
    if (placeIdx === numOfTriplets - 1) return "" // Ignore hundred for now
    for (let suffix in RoboNumberConstants.DIGIT_PLACE_INDEXES) {

      if (placeIdx === numOfTriplets - 1 -
        Number.parseInt(RoboNumberConstants.DIGIT_PLACE_INDEXES[suffix])) {
        largeNumberSuffix = ` ${suffix}`
        break;
      }
    }
    return largeNumberSuffix
  }

  // Gets an array of digit triplets given a string representation of a number
  getDigitTriplets = (numString: string): Triplet[] => {
    const triplets: Triplet[] = []
    const numArray: string[] = Array.from(numString)

    let tripletText = ""
    for (let i = 0; i < numArray.length; i++) {
      // Append the current digit to the triplet string
      tripletText += numArray[i]

      // If this digit is the third digit in a triplet, push the triplet string
      // into the triplets array & reset the triplet string
      if ((i + 1) % 3 === 0) {
        triplets.push(new Triplet(tripletText))
        tripletText = ""
      }
    }
    return triplets
  }
}
