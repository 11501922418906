// Contains constants shared across classes needed for RoboNumber Parsing
export default class RoboNumberConstants {
  // Map of numbers to their representation in actual words
  static NUMBER_WORDS: any = {
    "0": "Zero",
    "1": "One",
    "2": "Two",
    "3": "Three",
    "4": "Four",
    "5": "Five",
    "6": "Six",
    "7": "Seven",
    "8": "Eight",
    "9": "Nine",
    "10": "Ten",
    "11": "Eleven",
    "12": "Twelve",
    "13": "Thirteen",
    "14": "Fourteen",
    "15": "Fifteen",
    "16": "Sixteen",
    "17": "Seventeen",
    "18": "Eighteen",
    "19": "Nineteen",
    "20": "Twenty",
    "30": "Thirty",
    "40": "Forty",
    "50": "Fifty",
    "60": "Sixty",
    "70": "Seventy",
    "80": "Eighty",
    "90": "Ninety",
  }

  // Map of place words to their index within their respective triplet arrays
  static DIGIT_PLACE_INDEXES: any = {
    "Hundred": "0",
    "Thousand": "1",
    "Million": "2",
    "Billion": "3",
    "Trillion": "4",
    "Quadrillion": "5",
    "Quintillion": "6",
    "Sextillion": "7",
    "Septillion": "8",
    "Octillion": "9",
    "Nonillion": "10",
    "Decillion": "11"
  }

  // Index of the "relative" tens place for each triplet
  static RELATIVE_TENS_PLACE_INDEX: number = 1
}
