import React from "react"

import Layout from "../components/center-layout"
import SEO from "../components/seo"

import RoboNumber from "../components/robo-voice/robo-number"

/**
 * Page component.
 */
export default class RoboVoicePage extends React.Component {

  NUMBER_ENTERED = "numberEntered"
  NUMBER_PARSED = "numberParsed"
  PARSING_PREFIX = "Robots would robo-speak the number "

  /**
   * Component state.
   */
  state: any = {
    [this.NUMBER_ENTERED]: "",
    [this.NUMBER_PARSED]: "",
  }


  /* 
   * Handles number input & state management.
   */
  handleInput = (event: any) => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  /* 
   * Parses current number entered.
   */
  parseInput = (): void => {
    let parsedNumber = new RoboNumber(this.state.numberEntered)
    this.setState({
      [this.NUMBER_PARSED]:
      this.PARSING_PREFIX + parsedNumber.toString()
    })
  }

  /* 
   * Debug method.
   */
  debugState = (): void => {
    console.log(this.state)

  }

  /* 
   * Component render method.
   */
  render(): JSX.Element {
    return (
      <Layout>
        <SEO title="Robo-Voice" />
        <h1>Robo-Voice: An experiment where robots say numbers.</h1>
        <input type="number" name="numberEntered"
          value={this.state.numberEntered} onChange={this.handleInput} />
        <button onClick={this.parseInput}>Say This Number</button>
        <button onClick={this.debugState}>DEBUG: Log Current State</button>
        <p>{this.state.numberParsed}</p>

      </Layout>
    )
  }
}
